import React from "react"
import { Link } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

const Requirements = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/"
        location={location}
      />

      <div className="mt-gap-2" css={theme.components.row}>
        <p className="max-w-content">
          Some clinical conditions and treatment regimens require specific blood
          component specifications. This section represents the minimum
          requirements for the safe administration of blood component
          transfusions as per relevant BSH guidance.
        </p>

        <ul css={theme.components.linkButtonList}>
          <li>
            <Link
              to="/requirements/irradiation/"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Irradiation
            </Link>
          </li>

          <li>
            <Link
              to="/requirements/cmv-negative-components/"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              CMV Negative Components
            </Link>
          </li>

          <li>
            <Link
              to="/requirements/monoclonal-therapies"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Monoclonal Therapies
            </Link>
          </li>

          <li>
            <Link
              to="/requirements/washed-red-cells"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Washed Red Cells
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default Requirements
